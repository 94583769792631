<template>
  <div
    class="empty-wrapper"
    :style="{ height: height }">
    <div class="empty-item">
      <i :class="icon + '_empty-icon'"></i>
      <p v-if="text">{{ text }}</p>
      <b
        v-if="btnText"
        @click="btnTap">
        {{ btnText }}
      </b>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    // 组件高度  默认：100vh
    height: {
      type: String
    },
    // 缺省图标
    // market: 市场  shop: 店铺  order: 订单
    icon: {
      type: String,
      default: () => 'market'
    },
    // 文字说明
    text: {
      type: String
    },
    // 按钮文字，没有则不显示按钮
    btnText: {
      type: String
    }
  },
  data () {
    return {}
  },
  methods: {
    btnTap () {
      this.$emit('btn-tap')
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
