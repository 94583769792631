<template>
  <div class="st-wrapper shop-list-wrapper">
    <header-search placeholder="输入店铺名称搜索" @search="searchShop" />

    <ul class="shop-items" v-if="shopList.length > 0">
      <li
        v-for="item in shopList"
        :key="item.shop_id"
        class="st-item"
        @click="onShopItem(item)"
      >
        <div class="item-main">
          <h3>{{ item.name || '暂无店铺名' }}</h3>
          <b>
            <span>详情</span>
          </b>
        </div>
        <div class="item-sub">
          <span>地址：{{ item.address }}</span>
        </div>
      </li>
    </ul>

    <empty
      icon="restaurant"
      text="未获取到餐厅信息"
      height="calc(100vh - 3rem)"
      v-else
    />
  </div>
</template>

<script>
import HeaderSearch from '../../../components/header/search/Index'
import Empty from '../../../components/empty/Index'
import _ from 'lodash'

export default {
  name: '',
  components: {
    HeaderSearch,
    Empty
  },
  data () {
    return {
      shopList: [],
      list: []
    }
  },
  mounted () {
    this.getShopItems()
  },
  methods: {
    // 获取店铺列表
    getShopItems () {
      this.$api.restaurant.list().then(res => {
        // console.log(res)
        if (res.status === 200) {
          const list = []
          res.data.result.forEach(item => {
            if (item.address.indexOf('扬州') > -1) {
              list.push(item)
            }
          })
          this.shopList = this.list = list
        }
      })
    },
    // 搜索店铺
    searchShop (value) {
      if (value) {
        this.shopList = _.compact(_.map(this.list, item => {
          if (item.name && item.name.indexOf(value) > -1) {
            return item
          }
        }))
      } else {
        this.shopList = this.list
      }
    },

    // 点击店铺项
    onShopItem (item) {
      this.$router.push({
        name: 'EntryRecord',
        query: {
          bid: item.bid
          // bid: '5fea9ff7384591f76b58a7ae' // 测试 餐厅id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './index';
</style>
